import React, {useState} from "react";
import * as styles from "src/styles/CMMS-Software.module.css";
import { SEO, Picture } from "src/components/commonComponents";
import Modal from 'react-modal';

import Dot from "../../../static/staticPages/Dot.svg"
import Play1 from "../../../static/staticPages/Play1.svg"
import arrowRight from "../../../static/staticPages/arrowRight.svg"

import cmmsHero from "../../../static/staticPages/cmms_newhero.jpg"

import expandArrowsIcon from '../../../static/staticPages/expand-arrows.svg'
import cogsIcon from '../../../static/staticPages/cogs.svg'
import heartbeatIcon from '../../../static/staticPages/heartbeat.svg'
import mobileIcon from '../../../static/staticPages/mobile.svg'
import moneyCheckEditAltIcon from '../../../static/staticPages/money-check-edit-alt.svg'
import screenuserssolid from "../../../static/staticPages/screen-users-solid.svg"

import toolsIcon from '../../../static/staticPages/tools.svg'
import lockAltIcon from '../../../static/staticPages/lock-alt.svg'
import usersCogIcon from '../../../static/staticPages/users-cog.svg'
import fileCertificateIcon from '../../../static/staticPages/file-certificate.svg'
import phoneLaptopIcon from '../../../static/staticPages/phone-laptop.svg'
import fileChartColumnSolidIcon from "../../../static/staticPages/mc-product/file-chart-column-solid.svg";

import chatLineSolidIcon from '../../../static/staticPages/chart-line-solid.svg'
import prescriptionBottleMedicalSolidIcon from '../../../static/staticPages/prescription-bottle-medical-solid.svg'
import industryWindowsIcon from '../../../static/staticPages/industry-windows-solid.svg'

import featureProduct2 from "../../../static/staticPages/footer-famis-logo.png"
import arrowRightBlue from "../../../static/staticPages/arrowRightBlue.svg"
import spaceHero2 from "../../../static/staticPages/space-hero21.jpeg"

import lucernexLogo from "../../../static/staticPages/lucernex-logo.svg"
import redeyeLogo from "../../../static/staticPages/famis.svg"
import meridianLogo from '../../../static/staticPages/meridian-logo.png'

import resource1 from "../../../static/staticPages/cmms-resource-1.jpg"
import resource2 from "../../../static/staticPages/cmms-resource-2.jpg"
import resource3 from "../../../static/staticPages/cmms-resource-3.jpg"
import resource4 from "../../../static/staticPages/cmms-resource-4.jpg"
import resource5 from "../../../static/staticPages/cmms-resource-5.jpg"

import twoCol1 from "../../../static/staticPages/twoCol1.png"
import twoCol2 from "../../../static/staticPages/twoCol2.png"
import arrowDownBlue from "../../../static/staticPages/arrowDownBlue.svg"
import arrowUpBlue from "../../../static/staticPages/arrowUpBlue.svg"

import { useHeroImages } from "src/hooks/useHeroImages";
import { getPageDataJsonPath } from "src/utils";
import { useFormImages } from "src/hooks/useFormImages";
import { ContentMarketoForm } from "src/components/commonComponents/forms/ContentMarketoForm";

import customerTestimonialLogo from "../../../static/staticPages/encore_wire.png"

import alaskaAirlinesLogo from "../../../static/staticPages/logo6.svg"
import lorealLogo from "../../../static/staticPages/logo4.svg"
import newYorkTimesLogo from "../../../static/staticPages/logo5.svg"
import cookMedicalLogo from "../../../static/staticPages/cook_white.png"
import masterGalleryFoodsLogo from "../../../static/staticPages/masters_gray.png"

import famisLogo from "../../../static/staticPages/footer-famis-logo.png"
import mcLogo from "../../../static/staticPages/mc-logo.png"
import tmsLogo from "../../../static/staticPages/tms-logo.png"

import demoImage from "../../../static/staticPages/space-hero2.jpg";

const data = {
  heroBanner:{
    title: 'CMMS Software for Optimized Maintenance',
    description:'Automate work orders, lower costs, improve inventory accuracy, and maintain industry-specific regulatory compliance with our CMMS systems',
    link1: {
      url: '/request-demo',
      text: 'Request a Demo'
    },
    link2: {
      url: '/contactus',
      text: 'Contact Sales'
    },
    image: {
      url: cmmsHero,
      altText: 'A factory employee efficiently manages maintenance tasks using CMMS software on a computer, ensuring smooth operations.'
    }
  },
  features: {
    title: 'Optimizing Asset Lifecycle Management with Advanced CMMS Solutions',
    description: "Whether you're dealing with manufacturing precision, healthcare reliability, or the dynamic environments of retail and education, our CMMS adapts to your unique operational needs. Experience improved efficiency, reduced downtime, and extended asset longevity with Accruent",
    items: [
      {
        logoUrl: expandArrowsIcon,
        altText: "Expand arrow icon highlighting reliability",
        title: "Maximize Reliability with CMMS Precision",
        description: "Dramatically boost asset reliability by enabling predictive maintenance and minimizing unexpected breakdowns. Extend the lifespan of key assets and ensure consistent production with reduced downtime",
        link: {
          url: '/products/maintenance-connection',
          text: 'Explore the Solution'
        }
      },
      {
        logoUrl: cogsIcon,
        altText: "Three cogs icon highlighting work order automation",
        title: "Automate Work Order Scheduling",
        description: "Automate work order scheduling and align maintenance tasks with production cycles for optimal efficiency. Use this automation to enhance workflow coordination and significantly reduce the likelihood of machine downtime due to maintenance oversights or scheduling conflicts",
        link: {
          url: '/products/maintenance-connection',
          text: 'Explore the Solution'
        }
      },
      {
        logoUrl: heartbeatIcon,
        altText: "Heartbeat icon highlighting healthcare compliance",
        title: "Simplify Healthcare Compliance",
        description: "Ensure effortless adherence to The Joint Commission's EOC and PM standards with comprehensive tools for seamless data documentation, reporting, and retrieval through the entire asset management process",
        link: {
          url: '/products/tms',
          text: 'Explore the Solution'
        }
      },
      {
        logoUrl: moneyCheckEditAltIcon,
        altText: "Check icon highlighting smarter capital planning",
        title: "Enable Smarter Capital Planning ",
        description: "Elevate financial performance by maximizing asset availability, offering precise data for strategic equipment capital planning, and enhancing operations with intelligent automation",
        link: {
          url: '/products/tms',
          text: 'Explore the Solution'
        }
      },
      {
        logoUrl: screenuserssolid,
        altText: "Screen user icon",
        title: "Harness the Power of Space Management",
        description: "Enhance strategic space utilization, automate assignments, identify bottlenecks, monitor occupancy, and accelerate reporting for facilities managers",
        link: {
          url: '/products/famis-360',
          text: 'Explore the Solution'
        }
      },
      {
        logoUrl: mobileIcon,
        altText: "Mobile icon",
        title: "Enhance Mobile Efficiency",
        description: "Boost employee efficiency with FAMIS 360's mobile app. Automate work orders and perform inspections and maintenance on the go for optimal on-site task management and operational readiness",
        link: {
          url: '/products/famis-360',
          text: 'Explore the Solution'
        }
      }
    ],
    info:{
      title: "Software Built to Optimize Your Facility and Asset Maintenance",
      images:[
        {
            url:mcLogo,
            altText: 'Accruent Maintenance Connection Logo',
            link:'/products/maintenance-connection'
        },
        {
            url: tmsLogo,
            altText:"Accruent TMS Logo",
            link:'/products/tms'
        },
        {
          url: famisLogo,
          altText:"Accruent FAMIS 360 Logo",
          link:'/products/famis-360'
      }
        ],
        link: {
        text: 'Request a demo',
        url:'/request-demo'
        }
    }
  },
  fullWidthBanner:{
    image: {
      url: customerTestimonialLogo,
      altText:"Encore Wire logo"
    },
    title:"Asset Maintenance Manager, Encore Wire",
    description: "“The best part of Maintenance Connection - there's honestly a lot, so it's hard to pinpoint that one thing. For me, being a maintenance mechanic prior to moving up in this role, I've always apreciated the fact that you can access documents and drawings in the system at your fingertips with a tablet via MC Express on the shop floor in real-time.”",
    name: "Kyler Keel",
    position: "Asset Maintenance Manager",
    // companyName: " Minnesota Colleges and Universities"
  },
  logoSlider:{
    logoList:[
      {
        url:alaskaAirlinesLogo,
        altText: 'Alaska Airlines logo'
      },
      {
        url:lorealLogo,
        altText: "L'Oreal logo"
      },
      {
        url: newYorkTimesLogo,
        altText: 'The New York Times logo'
      },
      {
        url:cookMedicalLogo,
        altText: 'Cook Medical logo'
      },
      {
        url:masterGalleryFoodsLogo,
        altText:'Masters Gallery Foods logo'
      },
    ]
  },
  threeColTwoRowComp: {
    title:"Unlock Peak Performance with Powerful CMMS Capabilities",
    description: "Leverage advanced CMMS functionalities to improve accountability, increase productivity, bridge communication gaps, and drive your business towards operational excellence",
    items:[
      {
        logoUrl:  toolsIcon,
        altText: "Tools icon represting maintenance analytics",
        title:"Insightful Maintenance Analytics",
        description: "Use tailor-made reports and dashboards to pinpoint preventive tasks, manage KPIs, optimize performance, and track predictive activities with precision"
      },
      {
        logoUrl:  lockAltIcon,
        altText: "Lock icon representing cybersecurity",
        title:"Effective Cybersecurity Strategy for Healthcare",
        description: "Enable a preventive approach to cybersecurity with user-based permissions, standard operating procedures, automated alerts, and more"
      },
      {
        logoUrl:  usersCogIcon,
        altText: "Cogs icon representing facilities maintenance",
        title:"Comprehensive Facilities Maintenance Management",
        description: "Streamline service requests, control access, manage resources efficiently, and enhancing tenant engagement while reducing costs and saving time"
      },
      {
        logoUrl:  fileCertificateIcon,
        altText: "File certificate icon representing compliance",
        title:"Accurate Records for Compliance and Audit Preparedness",
        description: "Facilitate ongoing compliance with ever-changing regulations using functionalities like automated audit logs, searchable work records, intuitive document organization, and more"
      },
      {
        logoUrl:  phoneLaptopIcon,
        altText: "Phone and laptop icon",
        title:"Mobile, Multi-Site Asset Management",
        description: "Access key procedures and documentation anywhere, at any time to read meters, update work orders, use barcodes and QR codes, capture electronic signatures, or locate inventory"
      },
      {
        logoUrl:  fileChartColumnSolidIcon,
        altText: "File chart icon",
        title:"Comprehensive Integrations",
        description: "Use our native EDMS and IoT integrations, along with our RESTful API, to connect to the right tools and reach a more predictive state"
      }
    ]
  },
  twoColComp:{
    title: "CMMS Software Optimized For Your Industry",
    description: "Find the right Accruent CMMS for your unique industry and pain points",
    link: {
      url: '/industries',
      text: "Explore industries using Accruent software"
    },
    items:[
      {
        logo: {
          url: chatLineSolidIcon,
          alt:'Line chart icon'
        },
        title: "Manufacturing",
        description: "Streamline workflows, maintain regulatory compliance, reduce operational costs, and automate critical M&R processes in process and discrete manufacturing  ",
        link: {
          url: '/industries/manufacturing',
          text:'Explore CMMS for Manufacturing'
        }
      },
      {
        logo: {
          url: prescriptionBottleMedicalSolidIcon,
          alt:'Prescription bottle icon'
        },
        title: "Healthcare",
        description: "Face cybersecurity threats, staffing issues, and facility & asset reliability concerns head-on with TMS, our healthcare CMMS",
        link: {
          url: '/industries/healthcare',
          text:'Explore CMMS for Healthcare'
        }
      },
    ]
  },
  heroBanner1:{
    title: "Take a closer look at our CMMS software",
    description:'Find out if our CMMS solutions are the right fit for your business with a live, one-on-one demo with an Accruent expert',
    link1: {
      url: '/request-demo',
      text: 'Request a Demo'
    },
    link2: {
      url: '/contactus',
      text: 'Contact Sales'
    },
    image: {
      url: spaceHero2,
      alt: 'Two manufacturing professionals using CMMS software in a facility, focusing on equipment maintenance and management.'
    }
  },
  services: {
    title: "Products designed to integrate your world",
    description: "Accruent software allows you to fully optimize the lifecycle of your asset and facilities, offering connected workflows, integrated experiences and data-backed insights.",
    items:[
      {
        logo:{
          url: mcLogo,
          alt: "Accruent Maintenance Connection Logo"
        },
        title: "Multi-site, multi-industry CMMS software",
        description:"CMMS software reduces downtime, predicts maintenance needs and managers asset and equipment inventory.",
        link:{
          url: '/products/maintenance-connection',
          text: "Explore Maintenance Connection"
        }
      },
      {
        logo:{
          url: tmsLogo,
          alt: "Accruent TMS Logo"
        },
        title: "Healthcare CMMS Software for Maximum Flexibility",
        description:"Healthcare CMMS improves regulatory compliance while optimizing biomed and facility management operations.",
        link:{
          url: '/products/tms',
          text: "Explore TMS"
        }
      },
      {
        logo:{
          url: famisLogo,
          alt: "Accruent FAMIS 360 Logo"
        },
        title: "Engineering Document Management Made Easy",
        description:"Engineering document management system streamlines compliance and maximizes company-wide collaboration.",
        link:{
          url: '/products/famis-360',
          text: "Explore Famis 360"
        }
      },
    ]
  },
  twoColHeadingComp: {
    title:"Find the right Accruent software for your exact industry",
    items:[
      {
        image:{
          url: twoCol1,
          alt: 'AMIS 360 Space Planning'
        },
        title: "AMIS 360 Space Planning ",
        description:"Use FAMIS CMMS and Space Planning software to gain more control and visibility into the allocation of space across your portfolio.",
        info:[
          {text: "Automate space assignments by organizational department or functional activity"},
          {text: "Uncover additional square footage or discover square footage underutilization"},
          {text: "Identify and resolve capacity bottlenecks"},
          {text: "Integrate your space and facilities data for smarter decision-making"}
        ],
        link:{
          url:'/',
          text:"Access 360 planning brochure"
        }
      },
      {
        image:{
          url: twoCol2,
          alt: 'EMS Space Planning'
        },
        title: "EMS Space Planning",
        description:"EMS space management software provides key space usage insights that can help you cut costs, improve your bottom line, and maximize flexibility.",
        info:[
          {text: "Track critical metrics like space usage, cancellations, meeting types, service changes, and more"},
          {text: "Lower real estate costs with more than 120 pre-built, flexible utilization reports"},
          {text: "Integrate with building systems like maintenance and HVAC to automate processes and centralize insights"},
        ],
        link:{
          url:'/',
          text:"Explore space planning optimizations"
        }
      }
    ]
  },
  resources: {
    title: "Some resources you might find interesting",
    link:{
      url:'/resources',
      text:"Explore Accruent resources"
    },
    resourceList:{
      resource1: {
        image:{
          url: resource1,
          altText: "Experts collaborate on cybersecurity strategies, analyzing data and developing protection measures for healthcare systems."
        },
        title:"7 Best Practices for Cybersecurity in the Healthcare Environment",
        description:"Is your healthcare organization safe from cyberattacks? Discover the best practices to identify threats and boost cybersecurity for medical devices and facilities.",
        link: '/resources/white-papers/best-practices-for-cybersecurity-in-healthcare',
        target: '_self',
        button:{
          text: 'White Paper',
        }
      },
      resource2: {
        image:{
          url: resource2,
          altText: "An Encore employee discusses how Encore Wire uses Maintenance Connection software to optimize asset and inventory management."
        },
        title:"Encore Wire Uses Maintenance Connection to Optimize Asset and Inventory Management",
        description:"Hear from Encore Wire's Kyler Keel about how Maintenance Connection enables more predictive operations and simplifies asset & inventory management.",
        link: '/resources/case-studies/encore-wire-uses-maintenance-connection-optimize-asset-and-inventory',
        target: '_self',
        button:{
          text: 'Case Study',
        }
      },
      list:[
        {
          image:{
            url: resource3,
            altText: 'Unlocking the benefits of 3D in engineering and design'
          },
          title:"10 Steps to Choosing the Best CMMS Provider",
          link: 'https://website.maintenanceconnection.com/resources/ebooks/10-steps-choose-best-cmms-provider',
          target: '_blank',
          button:{
            text: 'Ebook',
          }
        },
        {
          image:{
            url: resource4,
            altText: 'Navigating Key Challenges in Food & Beverage Manufacturing in 2024'
          },
          title:"A factory worker uses CMMS mobile software, streamlining maintenance tasks and improving efficiency on the production floor.",
          link: 'https://website.maintenanceconnection.com/resources/blog-posts/give-me-connection-or-give-me-death-breathing-new-life-legacy-industrial-world',
          target: '_blank',
          button:{
            text: 'Blog',
          }
        },
        {
          image:{
            url: resource5,
            altText: 'A manufacturing plant worker uses CMMS software in the food and beverage industry to manage maintenance and ensure safety.'
          },
          title:"Why Food and Beverage Manufacturers Need to Invest in a CMMS",
          link: '/resources/webinars/why-food-and-beverage-manufacturers-need-invest-cmms',
          target: '_self',
          button:{
            text: 'Webinar',
          }
        },
      ]
    },
    walkthrough: {
      title:"Walkthrough demonstrations",
      items:[
        {
          logo:{
            url:Play1,
            altText: 'NA'
          },
          title:"Maintenance Connection: Work Order Management",
          description: "Easily review and assign work orders to your technicians and enable preventive and predictive maintenance programs",
          link: "https://accruent.wistia.com/medias/cp94edmtf3",
          wistiaId: 'cp94edmtf3'
        },
        {
          logo:{
            url:Play1,
            altText: 'NA'
          },
          title:"TMS Product Update 2024",
          description: "Dive into the new TMS experience, including new UI and powerful dashboards",
          link: "https://accruent.wistia.com/medias/mh80b40ktm",
          wistiaId: 'mh80b40ktm'
        },
        {
          logo:{
            url:Play1,
            altText: 'NA'
          },
          title:"CMMS, EDMS, and IoT Integration",
          description: "Check out our fully integrated CMMS, EDMS, and IoT software",
          link: "https://accruent.wistia.com/medias/eiyp0p6omt",
          wistiaId: 'eiyp0p6omt'
        },
        {
          logo:{
            url:Play1,
            altText: 'NA'
          },
          title:"FAMIS 360 Product Update 2024",
          description: "Explore the latest and greatest happening with FAMIS 360 CMMS and space planning software",
          link: "https://accruent.wistia.com/medias/ak7w0aqkkg",
          wistiaId: 'ak7w0aqkkg'
        }
      ]
    }
  },
  support:{
    logo:{
      url: featureProduct2,
      altText: 'Design Logo'
    },
    title: 'Frequently Asked Questions',
    list: [
      {
        title: 'How does CMMS software automate workflows?',
        descList: [
          {
            text: '<span>CMMS software aligns all team members by allowing them to easily assign, schedule, and process work orders into the system with ease.</span>'
          },
          {
            text: '<span>Streamline the entire work order lifecycle from initiation to completion, improving efficiency and reducing downtime. Real time tracking and <a target="_blank" href="https://website.maintenanceconnection.com/resources/blog-posts/how-populate-your-cmms-relevant-clean-data-so-you-can-make-effective-data">updates</a> allows managers and supervisors the ability to monitor the status of work orders and track technician progress.</span>'
          },
          {
            text: '<span>Pipelines can be set up in advance, allowing new customers to get added at the start of the workflow and be easily tracked throughout the purchase process.</span>'
          },
        ]
      },
      {
        title: 'How does CMMS software streamline maintenance tasks?',
        descList: [
          {
            text: '<span>Tasks are streamlined by providing a central repository of documents and information where users can easily refer.</span>'
          },
          {
            text: '<span>See asset details for every customer, analyze work orders, and reference historical data when needed. Do not waste time trying to locate customer data when it is not easily accessible.</span>'
          },
          {
            text: '<span>Mobile accessibility is another key bonus, giving disparate teams the ability to access information on the go.</span>'
          }
        ]
      },
      {
        title: 'Is SAP and CMMS software the same thing?',
        descList: [
          {
            text: '<span>No, SAP systems and CMMS software are not the same thing. SAP is an enterprise resource planning (ERP) software that is tasked with organization-wide duties like supply chain management, financial calculations, and customer relationship management.</span>'
          },
          {
            text: '<span>CMMS software is associated with improving maintenance management, from preventative maintenance scheduling to inventory management and more.</span>'
          },
          {
            text: '<span>Organizations who already rely on an SAP system might <a target="_blank" href="https://website.maintenanceconnection.com/resources/knowledge-hub/what-is-cmms-work-order-system">seek a CMMS</a> to complement their existing workflows.</span>'
          }
        ]
      },
      {
        title: 'How do CMMS and EAM differ?',
        descList: [
          {
            text: '<span>Both solutions are valuable for streamlining the maintenance process, but they differ slightly in terms of scope and use case.</span>'
          },
          {
            text: '<span>Enterprise Asset Management (EAM) software focuses on the entire lifecycle management of physical assets, from the acquisition phase through disposal. EAMs may include asset tracking, depreciation management, capital planning, and compliance management.</span>'
          },
          {
            text: '<span>CMMS software deals more specifically with day-to-day maintenance management, supporting equipment uptime and improving equipment operations.</span>'
          }
        ]
      },
      {
        title: 'What is the most unique and powerful feature of Accruent’s CMMS software?',
        descList: [
          {
            text: '<span>The ability to centralize maintenance management into one place, allowing team members to access data from separate locations at a moment’s notice.</span>'
          },
          {
            text: '<span>By organizing key data into a central software solution, companies can ensure more productivity and less downtime. Preventative tasks like equipment audits can be scheduled quickly and easily.</span>'
          },
          {
            text: '<span>This is especially lucrative for high-pressure industries like healthcare, where equipment health cannot be compromised.</span>'
          }
        ]
      },
    ],
    contact: {
      title: 'Don’t see your question? Let us help you',
      link: {
        url:'/contactus',
        text:'Contact support'
      }
    }
  },
}

const HeroBanner = (heroImages) => {
  return <>
    <div className={styles.heroContainer}>
      <div className={styles.leftWrapper}>
        <h1 className={styles.heroTitle}>
          {data?.heroBanner?.title}
        </h1>
        <p className={styles.heroDesc}>{data?.heroBanner?.description}</p>
        <div className={styles.heroBtnWrapper}>
          <a href={'#mktoForm'} className={styles.heroBtn1Wrapper}>
            <span className={styles.heroBtn1Text}>{data?.heroBanner?.link1?.text}</span>
            <div className={styles.heroBtn1}>
              <img className={styles.heroBtn1Img} src={Dot}></img>
            </div>
          </a>
          <a href={data?.heroBanner?.link2?.url} className={styles.heroBtn2Wrapper}>
            <span className={styles.heroBtn2Text}>{data?.heroBanner?.link2?.text}</span>
            <img src={arrowRight}></img>
          </a>
        </div>
      </div>
      <div>
        <Picture
            className={styles.heroImage}
            image={heroImages.heroImages['staticPages/cmms_newhero.jpg']}
            rel="preload"
            alt={data?.heroBanner?.image?.altText}
          />
      </div>
    </div>
  </>
}

const Question = ({item}) => {
  const [open, setOpen] = useState(false)
  return (
    <div className={styles.supportListWrapper}>
      <div onClick={()=>setOpen(!open)} className={styles.supportListTitleWrapper}>
        <img src={open ? arrowUpBlue : arrowDownBlue} alt='arrow'></img>
        <h3 className={styles.supportListTitle}>{item?.title}</h3>
      </div>
      {open && <div className={styles.supportListDescWrapper}>
          {
            item?.descList?.map((i, index)=>
              <p key={index} className={styles.supportListDesc} dangerouslySetInnerHTML={{ __html: i?.text }}>
              </p>
            )
          }
        </div>
      }
    </div>
  )
}

const Support = () => {
  return (
    <div className={styles.supportContainer}>
      <div className={styles.supportHeadWrapper}>
        {/* <img className={styles.supportLogo} src={data?.support?.logo?.url} alt={data?.support?.logo?.altText}></img> */}
        <h2 className={styles.supportTitle}>{data?.support?.title}</h2>
        <div className={styles.supportQListWrapper}>
        {
          data?.support?.list?.map((item, index)=>
            <Question key={index} item={item}/>
          )
        }
      </div>
      </div>
      <div className={styles.supportContactWrapper}>
        <p className={styles.supportContactText}>{data?.support?.contact?.title}</p>
        <a className={styles.supportContactLink} href={data?.support?.contact?.link?.url}>
          <span className={styles.supportContactLinkText}>{data?.support?.contact?.link?.text}</span>
          <img src={arrowRightBlue} alt='Right arrow'></img>
        </a>
      </div>
    </div>
  )
}

const Features = () => {
  return (
    <div className={styles.featureContainer}>
      <div className={styles.featureGridCompWrapper}>
        <div className={styles.featureHeadingWrapper}>
          <h2 className={styles.featureTitle}>{data?.features?.title}</h2>
          <p className={styles.featureDesc}>{data?.features?.description}</p>
        </div>
        <div className={styles.featureGridWrapper}>
          {
            data?.features?.items?.map((item,index)=>
              <div key={index} className={styles.featureGridCard}>
                <div className={styles.featureBox}>
                  <div className={styles.featureWrapper}>
                    <div className={styles.featureLogoWrapper}>
                      <img src={item?.logoUrl} alt={item?.altText} loading="lazy"></img>
                    </div>
                    <h3 className={styles.featureGridTitle}>
                      {item?.title}
                    </h3>
                    <p className={styles.featureGridDesc}>{item?.description}</p>
                  </div>
                </div>
                <a href={item?.link?.url} className={styles.featureCardLinkWrapper}>
                  <span className={styles.featureCardLinkText}>{item?.link?.text}</span>
                  <img src={arrowRightBlue} alt='Right arrow'></img>
                </a>
              </div>
            )
            
          }
        </div>       
      </div>
      <div className={styles.featureProductsWrapper}>
        <p className={styles.featureProductsText}>{data?.features?.info?.title}</p>
        <div className={styles.featureProductsLogoList}>
          {
            data?.features?.info?.images?.map((image,index)=>
              <a href={image?.link} key={index} className={styles.featureProductsLogoWrapper}>
                <img src={image?.url} alt={image?.altText} className={styles.featureProductsLogo} loading="lazy"></img>
              </a>
            )
          }
        </div>
        <a href={'#mktoForm'} className={styles.heroBtn1Wrapper}>
              <span className={styles.heroBtn1Text}>{data?.features?.info?.link?.text}</span>
              <div className={styles.heroBtn1}>
                <img className={styles.heroBtn1Img} src={Dot}></img>
              </div>
        </a>
      </div>
    </div>
  )
}

const FullWidthBanner = () => {
  return (
    <div className={styles.fullWidthBannerContainer}>
      <div className={styles.fullWidthBannerWrapper}>
        <div className={styles.fullWidthBannerLogoWrapper}>
          <div className={styles.fullWidthBannerLogo}>
            <img className={styles.fullWidthBannerImage} src={data?.fullWidthBanner?.image?.url} 
              alt={data?.fullWidthBanner?.image?.altText} loading="lazy"></img>
          </div>
        </div>
        <div className={styles.fullWidthBannerTextWrapper}>
          <p className={styles.fullWidthBannerTitle}>
            {data?.fullWidthBanner?.title}
          </p>
          <p className={styles.fullWidthBannerDesc}>{data?.fullWidthBanner?.description}</p>
          <div>
            <p className={styles.fullWidthBannerName}>— {data?.fullWidthBanner?.name}</p>
            <p className={styles.fullWidthBannerDesc}>{data?.fullWidthBanner?.position} { data?.fullWidthBanner?.companyName ? ", " + data?.fullWidthBanner?.companyName  : ""}</p>
          </div>
        </div>
      </div>
    </div>
  )
}

const LogoSlider = () => {
  return (
    <div className={styles.logoSliderContainer}>
      <div className={styles.logoStrip}>
      { data?.logoSlider?.logoList?.map((logo, index)=>
        <img key={index} className={styles.logoSliderImage} src={logo?.url} alt={logo?.altText} loading="lazy"></img>
      )
      }
      </div>
    </div>
  )
}

const ThreeColComp = () => {
  return (
    <div className={styles.threeColContainer}>
      <div className={styles.threeColHeadWrapper}>
        <h2 className={styles.threeColTitle}>{data?.threeColTwoRowComp?.title}</h2>
        <p className={styles.threeColDesc}>{data?.threeColTwoRowComp?.description}</p>
      </div>
      <div className={styles.threeColGridWrapper}>
        {
          data?.threeColTwoRowComp?.items?.map((item, index)=>
            <div key={index} className={styles.threeColGridBox}>
              <div className={styles.threeColGrid}>
                <div className={styles.threeColLogoWrapper}>
                  <img src={item?.logoUrl} alt={item?.altText} loading="lazy"></img>
                </div>
                <h3 className={styles.threeColGridTitle}>{item?.title}</h3>
                <p className={styles.threeColGridDesc}>{item?.description}</p>
              </div>
            </div>
          )
        }
      </div>
    </div>
  )
}

const TwoColComp = () => {
  return (
    <div className={styles.twoColCompContainer}>
      <div className={styles.twoColCompLeftWrapper}>
        <div className={styles.twoColCompTextWrapper}>
          <h2 className={styles.twoColCompTitle}>
              {data?.twoColComp?.title}
          </h2>
          <p className={styles.twoColCompDesc}>
              {data?.twoColComp?.description}
          </p>
        </div>
        <a href={data?.twoColComp?.link?.url} className={styles.twoColCompLinkWrapper}>
          <span className={styles.twoColCompLinkText}>{data?.twoColComp?.link?.text}</span>
          <img src={arrowRightBlue} alt='Right arrow'></img>
        </a>
      </div>
      <div className={styles.twoColCompRightWrapper}>
        {
          data?.twoColComp?.items?.map((item, index)=>
            <div key={index} className={styles.twoColCompCardWrapper}>
              <div className={styles.twoColCompCardTextWrapper}>
                <div className={styles.twoColCompCardLogoWrapper}>
                  <img src={item?.logo?.url} alt={item?.logo?.alt} loading="lazy"></img>
                </div>
                <h3 className={styles.twoColCompCardTitle}>{item?.title}</h3>
                <p className={styles.twoColCompCardDesc}>{item?.description}</p>
              </div>
              <a href={item?.link?.url} className={styles.twoColCompCardLink}>
                <span className={styles.twoColCompCardLinkText}>{item?.link?.text}</span>
                <img src={arrowRightBlue} alt='Right arrow'></img>
              </a>
            </div>
          )
        }
      </div>
    </div>
  )
}

const HeroBanner1 = () => {
  return <>
     <div className={styles.heroContainer1}>
        <div className={styles.leftWrapper}>
          <h2 className={styles.hero1Title}>
            {data?.heroBanner1?.title}
          </h2>
          <p className={styles.hero1Desc}>{data?.heroBanner1?.description}</p>
          <div className={styles.heroBtnWrapper}>
            <a href={'#mktoForm'} className={styles.heroBtn1Wrapper}>
              <span className={styles.heroBtn1Text}>{data?.heroBanner1?.link1?.text}</span>
              <div className={styles.heroBtn1}>
                <img className={styles.heroBtn1Img} src={Dot}></img>
              </div>
            </a>
            <a href={data?.heroBanner1?.link2?.url} className={styles.heroBtn2Wrapper}>
              <span className={styles.heroBtn2Text}>{data?.heroBanner1?.link2?.text}</span>
              <img src={arrowRight}></img>
            </a>
        </div>
        </div>
        <div>
          <img className={styles.heroImage} src={data?.heroBanner1?.image?.url} alt={data?.heroBanner1?.image?.alt} loading="lazy"></img>
        </div>
     </div>
  </>
}

const Services = () => {
  return (
    <div className={styles.serviceContainer}>
      <div className={styles.serviceHeadWrapper}>
        <h2 className={styles.serviceTitle}>{data?.services?.title}</h2>
        <p className={styles.serviceDesc}>{data?.services?.description}</p>
      </div>
      <div className={styles.serviceCardContainer}>
        {
          data?.services?.items?.map((item,index)=>
            <div key={index} className={styles.serviceCardWrapper}>
            <div className={styles.serviceCardTextWrapper}>
              <div className={styles.serviceCardLogoWrapper}>
                <img className={styles.serviceCardLogo} src={item?.logo?.url} alt={item?.logo?.alt} loading="lazy"></img>
              </div>
              <div className={styles.serviceCardText}>
                <h3 className={styles.serviceCardTitle}>{item?.title}</h3>
                <p className={styles.serviceCardDesc}>{item?.description}</p>
              </div>
            </div>
            <a href={item?.link?.url} className={styles.serviceCardLinkWrapper}>
              <span className={styles.serviceCardLinkText}>{item?.link?.text}</span>
              <img src={arrowRightBlue} alt='Right arrow'></img>
            </a>
          </div>
          )
        }
      </div>
    </div>
  )
}

const TwoColHeading = () => {
  return (
    <div className={styles.twoColContainer}>
      <h5 className={styles.twoColTitle}>
        {data?.twoColHeadingComp?.title}
      </h5>
      <div className={styles.twoColGridWrapper}>
       { data?.twoColHeadingComp?.items?.map((item, index)=>
          <div key={index} className={styles.twoColGriditemBox}>
            <div>
              <img className={styles.twocColGridImage} src={item?.image?.url} alt={item?.image?.alt} loading="lazy"></img>
              <div className={styles.twocColGridContentWrapper}>
                <div className={styles.twocColGridContent}>
                  <h5 className={styles.twocColGridTitle}>{item?.title}</h5>
                  <p className={styles.twocColGridDesc}>{item?.description}</p>
                    <ul className={styles.twocColGridDescList}>
                      { item?.info?.map((t, index)=>
                        <li key={index}>{t?.text}</li> 
                    )}
                    </ul>
                </div>
              </div>
            </div>
            <div className={styles.twoColGridLink}>
              <a href={item?.link?.url} className={styles.heroBtn2Wrapper}>
                    <span className={styles.heroBtn2Text}>{item?.link?.text}</span>
                    <img src={arrowRight}></img>
              </a>
            </div>
          </div>
      )
        }
      </div>
    </div>
  )
}

const Resource = () => {
  return (
    <div className={styles.resourceContainer}>
      <div className={`${styles.resourceHeadingWrapper} container`}>
        <h2 className={styles.resourceTitle}>{data?.resources?.title}</h2>
        <a href={data?.resources?.link?.url} className={styles.resourceLinkWrapper}>
          <span className={styles.resourceLinkText}>{data?.resources?.link?.text}</span>
          <img src={arrowRightBlue} alt='arrow button'></img>
        </a>
      </div>
      <div className={styles.resourceWrapper}>
        <div className={`${styles.resourceCardWrapper} container`}>
          <a href={data?.resources?.resourceList?.resource1?.link} target={data?.resources?.resourceList?.resource1?.target} className={styles.resourceCard}>
            <img className={styles.resourceCardImg} src={data?.resources?.resourceList?.resource1?.image?.url} alt={data?.resources?.resourceList?.resource1?.image?.altText} loading="lazy"/>
            <div className={styles.resourceCardTextWrapper}>
              <p className={styles.resourceCardTitle}>{data?.resources?.resourceList?.resource1?.title}</p>
              <p className={styles.serviceCardDesc}>{data?.resources?.resourceList?.resource1?.description}</p>
              <div className={styles.resourceCardBtnWrapper + " tw-static lg:tw-absolute tw-bottom-[30px]"}>
                <div className={styles.resourceCardBtn}>{data?.resources?.resourceList?.resource1?.button?.text}</div>
              </div>
            </div>
          </a>
          <a href={data?.resources?.resourceList?.resource2?.link} target={data?.resources?.resourceList?.resource2?.target} className={styles.resourceCard}>
            <img className={styles.resourceCardImg} src={data?.resources?.resourceList?.resource2?.image?.url} alt={data?.resources?.resourceList?.resource2?.image?.altText} loading="lazy"/>
            <div className={styles.resourceCardTextWrapper}>
              <p className={styles.resourceCardTitle}>{data?.resources?.resourceList?.resource2?.title}</p>
              <p className={styles.serviceCardDesc}>{data?.resources?.resourceList?.resource2?.description}</p>
              <div className={styles.resourceCardBtnWrapper + " tw-static lg:tw-absolute tw-bottom-[30px]"}>
                <div className={styles.resourceCardBtn}>{data?.resources?.resourceList?.resource2?.button?.text}</div>
              </div>
            </div>
          </a>
          <div className={styles.resourceMultiCardWrapper}>
            {
              data?.resources?.resourceList?.list?.map((item, index) =>
                <a key={index} target={item.target} href={item?.link} className={styles.resourceMultiCard}>
                  <div>
                    <img className={styles.resourceMultiCardImg} src={item?.image?.url} alt={item?.image?.altText}></img>
                    <div className={styles.resourceCardBtnWrapper + " md:!tw-hidden !tw-flex tw-mt-[15px] tw-w-fit"}>
                      <div className={styles.resourceCardBtn}>{item?.button?.text}</div>
                    </div>
                  </div>
                  <div className={styles.resourceMultiCardTextWrapper}>
                    <p className={styles.resourceMultiCardText}>{item?.title}</p>
                    <div className={styles.resourceCardBtnWrapper + " md:!tw-flex !tw-hidden"}>
                      <div className={styles.resourceCardBtn}>{item?.button?.text}</div>
                    </div>
                  </div>
                </a>
              )
            }
          </div>
        </div>
      </div>
      <div className={styles.walkthroughWrapper}>
        <h2 className={styles.walkthroughTitle}>{data?.resources?.walkthrough?.title}</h2>
        <div className={styles.walkthroughFlexWrapper}>
          <VideoGrid />
        </div>
      </div>
    </div>
  )
}

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    padding: '0',
    border: 'none',
    borderRadius: '0px',
    overflow: 'hidden'
  },
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, 0.75)'
  }
};

const closeButtonStyles = {
  position: 'absolute',
  top: '10px',
  right: '10px',
  background: 'rgba(0, 0, 0, 0.5)',
  border: 'none',
  fontSize: '20px',
  cursor: 'pointer',
  color: '#fff',
  zIndex: '1000',
  padding: '5px',
};

const VideoModal = ({ isOpen, onRequestClose, wistiaId }) => (
  <Modal isOpen={isOpen} onRequestClose={onRequestClose} style={customStyles}>
    <div>
      <button onClick={onRequestClose} style={closeButtonStyles}>x</button>
      <div
        className={`wistia_embed wistia_async_${wistiaId} videoFoam=true`}
        style={{height:'360px', width:'640px'}}
      >
        &nbsp;
      </div>
    </div>
  </Modal>
);

const VideoGrid = () => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [currentVideo, setCurrentVideo] = useState(null);

  const openModal = (wistiaId) => {
    setCurrentVideo(wistiaId);
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
    setCurrentVideo(null);
  };

  return (
    <>
      {data?.resources?.walkthrough?.items?.map((video, index) =>
        <div className={styles.walkthroughCardWrapper} onClick={() => openModal(video.wistiaId)}>
          <img src={video?.logo?.url} alt={video?.logo?.altText}></img>
          <div className={styles.walkthroughCardText}>
            <p className={styles.walkthroughCardTitle}>{video?.title}</p>
            <p className={styles.walkthroughCardDesc}>{video?.description}</p>
          </div>
        </div>
      )
      }
      <VideoModal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        wistiaId={currentVideo}
      />
    </>
  );
};

export default function EngineeringDocumentManagementSoftware() {
  const heroImages = useHeroImages();
  const pageDataPath = getPageDataJsonPath('/solutions/cmms-software')
  const formImages = useFormImages()
  const contentEntity = {
    "uuid": "abfd837b-6954-4cb8-a9c1-9c100cccda3b",
    "nid": 4046,
    "fieldEmbedCode": "<script src=\"//app-abc.marketo.com/js/forms2/js/forms2.min.js\"></script>\r\n<form id=\"mktoForm_1693\"></form>\r\n<script>MktoForms2.loadForm(\"//app-abc.marketo.com\", \"167-BOY-362\", 1693, function(form) {\r\n    dataLayer.push({\r\n      'event': 'gaTriggerEvent',\r\n      'gaCategory': 'CTA Engagement',\r\n      'gaAction': 'Form Load',\r\n      'gaLabel': 'Demo Request',\r\n    });\r\n    form.onSuccess(function(values, followUpUrl) {\r\n      dataLayer.push({\r\n        'event': 'gaTriggerEvent',\r\n        'gaCategory': 'CTA Engagement',\r\n        'gaAction': 'Form Submission',\r\n        'gaLabel': 'Demo Request',\r\n      });\r\n    });\r\n  });\r\n</script>",
    "title": "Request A Demo ",
    "fieldFormHeader": "Request A Demo",
    "fieldHideTitle": false,
    "fieldShowTitleFullWidth": null,
    "fieldFormText": null,
    "fieldFormImage": {
        "alt": "Two facility workers using CMMS software on a laptop for maintenance management"
    },
    "relationships": {
        "fieldFormImage": {
        },
        "fieldFormImageWithCover": formImages['staticPages/form-face.png'],
        "fieldMediaImage": {
            "fieldImage": {
                "alt": "bg"
            },
            "relationships": {
                "field_image": formImages['staticPages/form-bg.jpg'],
            }
        }
    },
    "type": {
        "id": "marketo_forms"
    },
    "fieldVersions": "advanced",
    "fieldCtaUrl": null
  }
    return (
      <>
        <SEO
          meta={
            [
              { 'name': 'title', 'content': 'Best CMMS Software for Maintenance Management | Accruent' },
              { 'name': 'description', 'content': "Discover the best CMMS software for maintenance management. Optimize operations, boost productivity, and reduce downtime with Accruent's CMMS solution." }
            ]
          }
          heroImage={heroImages['staticPages/edm-hero.jpg']}
          preloads= {[pageDataPath]}
          lang="en"
          pageUrl="/solutions/cmms-software"
          alternatives={[
            {
                "href": "/solutions/cmms-software",
                "hreflang": "en"
            },
            {
                "href": "/uk/solutions/cmms-software",
                "hreflang": "en-gb"
            },
            {
                "href": "/solutions/cmms-software",
                "hreflang": "x-default"
            }
        ]}
        />
          <HeroBanner heroImages={heroImages}/>
          <Features/>
          <FullWidthBanner/>
          <LogoSlider/>
          <ThreeColComp/>
          <TwoColComp/>
          <HeroBanner1/>
          <Services/>
          {/* <TwoColHeading/> */}
          <Resource/>
          <div id="mktoForm">
            <ContentMarketoForm
              entity={contentEntity}
              locale="en"
              pageTitle="CMMS: Best CMMS Software for Maintenance Management"
              formFullWidth={false}
              demoImage={demoImage}
            />
          </div>
          <Support/>
      </>
    );
  }
  
